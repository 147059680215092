@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300&display=swap');

.clients-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #00091b 0%, #0a1a3d 100%);
  color: #fff;
  font-family: 'Space Grotesk', sans-serif;
  position: relative;
  padding-top: 60px;
  overflow: visible;
}

.clients-hero {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: none;
}

.clients-hero::after {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, rgba(79, 235, 254, 0.1) 0%, transparent 50%);
  animation: pulse 8s ease-in-out infinite;
  pointer-events: none;
}

@keyframes pulse {
  0% { transform: scale(0.8); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 0.8; }
  100% { transform: scale(0.8); opacity: 0.5; }
}

.clients-hero h1 {
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #fff, #4febfe);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation: fadeInUp 1s ease forwards;
}

.clients-hero p {
  font-size: 1.2rem;
  opacity: 0;
  animation: fadeInUp 1s ease 0.3s forwards;
}

.client-section {
  min-height: 70vh;
  display: flex;
  align-items: center;
  padding: 2rem 4rem;
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  margin: 2rem 0;
}

.client-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.client-section.right {
  flex-direction: row-reverse;
}

.client-content {
  flex: 1;
  padding: 2rem;
  position: relative;
  z-index: 2;
  text-align: left;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;
}

.logo-separator {
  font-size: 2rem;
  opacity: 0.7;
}

.client-logo {
  height: auto;
  filter: brightness(0) invert(1);
  transition: filter 0.3s ease;
}

.client-logo:hover {
  filter: brightness(0) invert(1) drop-shadow(0 0 10px rgba(79, 235, 254, 0.5));
}

.client-content h2 {
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #fff, #4febfe);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.1;
}

.client-content p {
  font-size: 1.3rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  font-weight: 300;
  letter-spacing: 0.02em;
}

.project-visual {
  flex: 1;
  position: relative;
  padding: 2rem;
}

.project-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transform: perspective(1000px) rotateY(5deg) translateZ(0);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.client-section.right .project-image-container {
  transform: perspective(1000px) rotateY(-5deg);
}

.project-image-container:hover {
  transform: perspective(1000px) rotateY(0deg) translateZ(0);
}

.project-image-container:hover .project-image {
  transform: scale(1.15);
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(79, 235, 254, 0.1), transparent);
  pointer-events: none;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .client-section {
    padding: 2rem;
    min-height: auto;
    margin: 1rem 0;
  }

  .client-section.right {
    flex-direction: column;
  }

  .project-visual {
    margin-top: 3rem;
  }

  .clients-hero h1 {
    font-size: 3rem;
  }

  .client-content h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .clients-hero {
    height: 40vh;
  }

  .client-section {
    padding: 3rem 1.5rem;
  }
}

.content-wrapper {
  position: relative;
  z-index: 1;
  margin-left: -10px;
  text-align: left;
}

.description {
  font-family: 'DM Sans', sans-serif;
  font-size: 1.3rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin-bottom: 2rem;
  text-align: left;
  padding-right: 2rem;
}

.stats {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  background: linear-gradient(45deg, #4febfe, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 2rem;
  text-align: left;
}

/* Specific styles for Acura section */
.client-section:nth-child(3) .description {
  font-size: 1.4rem;
  line-height: 1.7;
}

/* Ensure right-aligned sections maintain text alignment */
.client-section.right .content-wrapper {
  margin-right: 10px;
  margin-left: -10px;
}

/* Ensure section numbers stay far left even in right-aligned sections */
.client-section.right .section-number {
  left: -4rem;
}

.section-number {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 7rem;
  font-weight: 700;
  opacity: 0.1;
  position: absolute;
  top: -2rem;
  left: -4rem;
  color: #4febfe;
  z-index: 0;
}

/* Specific style for Netflix section number */
.client-section:nth-child(2) .section-number {
  top: -4.5rem;
}

/* Specific style for Acura section */
.client-section:nth-child(3) {
  .section-number {
    top: 0rem;
  }
  
  .logo-container {
    margin-bottom: 1rem;
  }

  .content-wrapper {
    margin-top: -1rem;
    transform: translateY(-30px);
  }
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
} 