.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 9, 27, 0.9);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.01));
  border: 1px solid rgba(79, 235, 254, 0.2);
  border-radius: 15px;
  padding: 3rem;
  width: 90%;
  max-width: 700px;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  animation: slideUp 0.3s ease;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.modal-close:hover {
  opacity: 1;
}

.modal-body {
  color: white;
  font-family: 'Space Grotesk', sans-serif;
}

.modal-body h2 {
  font-size: 2.4rem;
  margin-bottom: 2.5rem;
  background: linear-gradient(45deg, #fff, #4febfe);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  opacity: 0.9;
}

.form-group textarea,
.form-group input {
  width: 100%;
  padding: 0.8rem 1.2rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(79, 235, 254, 0.2);
  border-radius: 8px;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.form-group textarea:focus,
.form-group input:focus {
  outline: none;
  border-color: rgba(79, 235, 254, 0.5);
  background: rgba(255, 255, 255, 0.08);
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(79, 235, 254, 0.2), rgba(79, 235, 254, 0.1));
  border: 1px solid rgba(79, 235, 254, 0.3);
  border-radius: 8px;
  color: white;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: linear-gradient(135deg, rgba(79, 235, 254, 0.3), rgba(79, 235, 254, 0.2));
  transform: translateY(-2px);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 